import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceOne from "./ServiceOne";
import ServiceTwo from "./ServiceTwo";
import ServiceThree from "./ServiceThree";
import ServiceFour from "./ServiceFour";
import ServiceFive from "./ServiceFive";
import Separator from "../separator/Separator";
import TimelineTwo from '../timeline/TimelineTwo';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Service = () => {
    return (
        <>
            <Layout>
                {/* <BreadcrumbOne 
                    title="Podemos apoyar su proyecto de diferentes maneras.<br/> ¿Qué tal si comenzamos con una sesión de descubrimiento?"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Service"
                /> */}
                <div className="main-content">
                
                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-right height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="subtitle color-white">Podemos apoyar su proyecto de diferentes maneras.</h1>
                                    <p className="theme-gradient">Permítenos tomar el control con nuestro equipo especializado para ayudarte a crear un plan de acción ideal para tu proyecto antes de desarrollar completamente un producto real.</p>
                                    
                                    <div className="button-group mt--30 mt_sm--20">
                                        <Link className="btn-default btn-medium btn-icon btn-border" to="/contact" >Contactanos <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/banner/banenr-image-02.png" alt="Banner Images" />
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                    {/* Start Timeline Area  */}
                    <div className="rwt-timeline-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Proceso preliminar"
                                            title = ""
                                            description = "Somos una extensión de tu equipo, que se amplía o reduce en función de tus necesidades."
                                        />
                                </div>
                            </div>
                            <div className="row mt_dec--20">
                                <div className="col-lg-12">
                                    <TimelineTwo />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Timeline Area  */}

                    {/* Start Service Area  */}
                    {/* <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Tú tienes la idea, nosotros sabemos cómo llevarla a cabo."
                                    title = "Servicios que ofrecemos."
                                    description = "El poder de la tecnología para hacerte crecer, desde la estrategia, el diseño, el desarrollo, <br/> y los servicios gestionados de TI."
                                />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                                textAlign = "text-center"
                            />
                        </div>
                    </div> */}
                    {/* End Service Area  */}  

                    
                    {/* <Separator /> */}

                    {/* Start Service Area  */}
                    {/* <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                    />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                                textAlign = "text-start"
                            />
                        </div>
                    </div> */}
                    {/* End Service Area  */}

                    {/* <Separator /> */}

                    {/* Start Service Area  */}
                    {/* <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Tú tienes la idea, nosotros sabemos cómo llevarla a cabo."
                                        title = "Servicios que ofrecemos."
                                        description = "El poder de la tecnología para hacerte crecer, desde la estrategia, el diseño, el desarrollo, <br/> y los servicios gestionados de TI."
                                    />
                                </div>
                            </div>
                            <ServiceTwo 
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                            />
                        </div>
                    </div> */}
                    {/* End Service Area  */}

                    {/* <Separator /> */}

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Tú tienes la idea, nosotros sabemos cómo llevarla a cabo."
                                        title = "Servicios que ofrecemos."
                                        description = "El poder de la tecnología para hacerte crecer, desde la estrategia, el diseño, el desarrollo, <br/> y los servicios gestionados de TI."
                                    />
                                </div>
                            </div>
                            <ServiceThree 
                                serviceStyle = "service__style--2"
                                textAlign = "text-center"
                            />
                        </div>
                    </div>
                    {/* End Service Area  */}   

                    {/* <Separator /> */}


                    {/* Start Service Area  */}
                    {/* <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Tú tienes la idea, nosotros sabemos cómo llevarla a cabo."
                                        title = "Servicios que ofrecemos."
                                        description = "El poder de la tecnología para hacerte crecer, desde la estrategia, el diseño, el desarrollo, <br/> y los servicios gestionados de TI."
                                    />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 icon-circle-style"
                                textAlign = "text-center"
                            />
                        </div>
                    </div> */}
                    {/* End Service Area  */}


                    {/* <Separator /> */}


                    {/* Start Service Area  */}
                    {/* <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                    />
                                </div>
                            </div>
                            <ServiceFour 
                                serviceStyle = "service__style--1 icon-circle-style with-working-process"
                                textAlign = "text-center"
                            />
                        </div>
                    </div> */}
                    {/* End Service Area  */}
                    

                    {/* <Separator /> */}

                    {/* Start Service Area  */}
                    {/* <div className="rn-service-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                    />
                                </div>
                            </div>
                            <ServiceFive 
                                serviceStyle = "gallery-style"
                                textAlign = "text-start"
                            />
                        </div>
                    </div> */}
                    {/* End Service Area  */}
                    
                </div>

            </Layout>
            
        </>
    )
}

export default Service;
