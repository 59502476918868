import React from 'react';
import {Link} from "react-router-dom";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiPhoneCall, FiMapPin, FiMail } from "react-icons/fi";
import Logo from '../../elements/logo/Logo';

const footerIntem =  footerOne[0];
const footerIntemOne =  footerOne[1];
const footerIntemTwo =  footerOne[2];
const footerIntemThree =  footerOne[3];
const footerIntemFour =  footerOne[4];
const footerIntemFive =  footerOne[5];

const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);
const indexThreeLink = (footerIntemThree.quicklink);

const FooterFive = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default variation-two">
                {/* <CalltoActionSeven /> */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Widget  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="logo">
                                        <Logo 
                                            image={`${process.env.PUBLIC_URL}/images/logo/fulllogo_transparent_nobuffer.png`}
                                            image2={`${process.env.PUBLIC_URL}/images/logo/fulllogo_transparent_nobuffer.png`}
                                        />
                                    </div>
                                    {/* <h3 className="text-medium">{footerIntem.title}</h3> */}
                                    <h6 className="text-medium">República Dominicana</h6>
                                    <div><FiMapPin/> Av. República de Colombia, Ciudad Real II, Santo Domingo.</div>
                                    <p className="text-item"><FiPhoneCall/>  +1 (849) 408-1214</p>
                                    <p className="text-item"><FiMail/> info@xcales.com</p>
                                </div>
                                
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <div className="widget-menu-top">
                                        <h4 className="title">{footerIntemTwo.title}</h4>
                                        <div className="inner">
                                            <ul className="footer-link link-hover">
                                                {indexTwoLink.map((data, index) => (
                                                    <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemThree.title}</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            {indexThreeLink.map((data, index) => (
                                                <li key={index}><Link to={`${data.url}`}>{data.text}</Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}

                            {/* Start Single Widget  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">{footerIntemFive.title}</h4>
                                    <div className="inner">
                                        <h6 className="subtitle">{footerIntemFive.subtitle}</h6>
                                        <ul className="social-icon social-default justify-content-start">
                                            <li><a target="_blank" href="https://facebook.com/xcales_rd" rel="noreferrer"><FiFacebook style={{"color": "#3c5a99"}} /></a></li>
                                            <li><a target="_blank" href="https://twitter.com/xcales_rd" rel="noreferrer"><FiTwitter style={{"color": "#1DA1F2"}} /></a></li>
                                            <li><a target="_blank" href="https://www.instagram.com/xcales.rd/" rel="noreferrer"><FiInstagram style={{"color": "purple"}} /></a></li>
                                            <li><a target="_blank" href="https://www.linkedin.com/company/xcales/" rel="noreferrer"><FiLinkedin style={{"color": "#0077b5"}} /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterFive;
