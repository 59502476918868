import {useState} from "react";
import { FiX , FiArrowRight} from "react-icons/fi";
import { Link } from "react-router-dom";

const HeaderTopNews = () => {
    const [deactive, setClass] = useState('');
    return (
        <div className={`header-top-news bg_image ${deactive}`}  >
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <div className="content">
                                    <span className="rn-badge">Falta poco</span>
                                    <span className="news-text">Aún estamos en desarrollo, <strong>solo queremos saber tus sugerencias antes de la version final</strong></span>
                                </div>
                                <div className="right-button">
                                <Link className="btn-read-more"  to={"/contact"}><span>Sugerencias <FiArrowRight /></span></Link>
                                    {/* <a className="btn-read-more" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular"><span>Purchase Now <FiArrowRight /></span></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="icon-close">
                <button className="close-button" onClick={() => setClass('deactive')}><FiX /></button>
            </div>
        </div>
    )
}

export default HeaderTopNews;
