import React from 'react'
import { Link } from "react-router-dom";
import Typed from 'react-typed';
import { FiArrowRight } from "react-icons/fi";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import Separator from "../elements/separator/Separator";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import BlogClassicData from '../data/blog/BlogList.json';
import FooterFive from '../common/footer/FooterFive';
import TestimonialOne from '../elements/testimonial/TestimonialOne';
import Layout from '../common/Layout';
import ServiceFour from '../elements/service/ServiceFour';
import ServiceThree from '../elements/service/ServiceThree';
import ServiceTwo from '../elements/service/ServiceTwo';
import ServiceOne from '../elements/service/ServiceOne';
var BlogListData = BlogClassicData.slice(0, 3);


const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes',
        ],
    }
]

const HomeDefault = () => {

    return (
        <Layout>
            {/* Start Slider Area  */}
            <div className="slider-area slider-style-1 variation-default height-850 bg_image" data-black-overlay="7" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-3.jpg)` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <span className="subtitle">MAS QUE SOLUCIONES TEGNOLÓGICAS</span>
                                <h2 className="title theme-gradient display-three">Unicos en<br /> {" "}
                                    <Typed
                                        strings={[
                                            "Transformación Digital.",
                                            "Desarrollo de Software.",
                                            "Consultoría.",
                                            "Automatización.",
                                            "Outsourcing.",
                                            "y Mucho mas ..."
                                        ]}
                                        typeSpeed={50}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p className="description">Ayudamos a nuestros clientes de todos los tamaños a construir una presencia digital que brinde resultados y aporte valor a las empresas.</p>
                                <div className="button-group">
                                    {/* <a className="btn-default btn-medium round btn-icon" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Purchase Now <i className="icon"><FiArrowRight /></i></a> */}
                                    <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactanos <i className="icon"><FiArrowRight /></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Slider Area  */}

            {/* Start Service Area  */}
            <div className="rn-service-area rn-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                textAlign="text-center"
                                radiusRounded=""
                                subtitle="Tú tienes la idea, nosotros sabemos cómo llevarla a cabo."
                                title="Servicios que ofrecemos."
                                description="El poder de la tecnología para hacerte crecer, desde la estrategia, el diseño, el desarrollo, <br/> y los servicios gestionados de TI."
                            />
                        </div>
                    </div>
                    <ServiceOne
                        serviceStyle="service__style--1 icon-circle-style"
                        textAlign="text-center"
                    />
                </div>
            </div>
            {/* End Service Area  */}

            <Separator />
            {/* Start Portfolio Area  */}
            <div className="rwt-portfolio-area rn-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                textAlign="text-center"
                                radiusRounded=""
                                subtitle="Casos de estudios"
                                title="Proyectos"
                                description="Obtenga soluciones específicas para su negocio, confiables y rentables."
                            />
                        </div>
                    </div>
                    <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
                </div>
            </div>
            {/* End Portfolio Area  */}

            {/* Start Video Area  */}
            {/* <div className="rwt-video-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            {PopupData.map((item) => (
                                <div className="col-lg-12" key={item.id}>
                                    <VideoItem galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                    </div> 
                */}
            {/* End Video Area  */}

            <Separator />
            {/* Start Testimonial Area  */}
            <div className="rwt-testimonial-area rn-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb--10">
                            <SectionTitle
                                textAlign="text-center"
                                radiusRounded=""
                                subtitle="Nuestros clientes"
                                title="Testimonios."
                            />
                        </div>
                    </div>
                    <TestimonialOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one" />
                </div>
            </div>
            {/* End Testimonial Area  */}

            {/* Start Call to Action Area  */}
            <div className="rwt-callto-action-area rn-section-gapBottom">
                <div className="wrapper">
                    <CalltoActionFive />
                </div>
            </div>
            {/* End Call to Action Area  */}
        </Layout>
    )
}
export default HomeDefault

