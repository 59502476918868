import React from 'react';
import HeaderOne from "./header/HeaderOne";
import Copyright from "./footer/Copyright";
import FooterFive from './footer/FooterFive';
import SEO from './SEO';
import HeaderTopNews from './header/HeaderTopNews';

const Layout = ({children}) => {
    return (
        <>
        <SEO title="Xcales" />
        <main className="page-wrapper">
            <HeaderTopNews />
            <HeaderOne  btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
            {children}
            <FooterFive />
            <Copyright /> 
        </main>            
    </>
    )
}
export default Layout;
