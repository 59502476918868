import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FaGlobe, FaMobileAlt } from 'react-icons/fa';
import { FiActivity } from 'react-icons/fi';

const ServiceList = [
    {
        icon: <FiActivity />,
        image: '/images/service/icon-01.png',
        title: 'Consultoría TI',
        description: 'Te ayudamos a cómo usar las tecnologías de la información con el fin de alcanzar tus objetivos personales o empresariales.'
    },
    {
        icon: <FaGlobe />,
        image: '/images/service/icon-02.png',
        title: 'Desarrollo de Software',
        description: `Nuestro equipo de ingenieros y desarrolladores tienen amplio conocimientos en la Web, App moviles, Base de Datos y gran gama de tecnolgias a tus disposición.`
    },
    {
        icon: <FaGlobe />,
        image: '/images/service/icon-03.png',
        title: 'Transformación Digital',
        description: 'Construimos soluciones de software de pricipio a fin, entregándo un producto tecnológico completo.'
    }
]

const ServiceThree = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div>
                                <div className="content">
                                    <h4 className="title"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceThree;