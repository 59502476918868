import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TabTwo = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-5">
                                    <img className="radius-small" src='./images/tab/xcales-icononly.png' alt="Corporate React Template" />
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab style-two">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Misión</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Visión</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>Valores</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>Lograr que nuestros clientes sean más eficientes, brindándoles soluciones y servicios tecnológicos innovadores, que aporten valor a sus proyectos, estableciendo una relación de confianza y apoyo en el logro de sus objetivos estratégicos.</p>

                                                        <p>Satisfacer las necesidades de nuestros clientes y asegurar que alcancen sus resultados, convirtiéndonos así en sus aliados estratégicos.                                                                                                     </p>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <p>Ser una empresa referente en soluciones tecnológicas calificadas, a nivel nacional e internacional, donde podamos agregar valor tanto a pequeños, medianos y grandes proyectos. Donde lleguemos a ser una gran familia de coolaboradores, destacados por la unidad y trabajo en equipo.</p>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="row inner">
                                                        <div className='col-md-6'>
                                                            <li>Trabajo en equipo</li>
                                                            <li>Honestidad</li>
                                                            <li>Integridad</li>
                                                            <li>Responsabilidad</li>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <li>Excelencia</li>
                                                            <li>Calidad</li>
                                                            <li>Respeto</li>
                                                            <li>Compromiso</li>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabTwo
