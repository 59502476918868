import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import AboutOne from '../elements/about/AboutOne';
import AboutTwo from '../elements/about/AboutTwo';
import AboutThree from '../elements/about/AboutThree';
import AboutSix from '../elements/about/AboutSix';
import AboutFive from '../elements/about/AboutFive';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TeamOne from "../elements/team/TeamOne";
import TeamTwo from "../elements/team/TeamTwo";
import TeamThree from "../elements/team/TeamThree";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import TabThree from '../elements/tab/TabThree';
import TabTwo from '../elements/tab/TabTwo';
import Layout from '../common/Layout';

const AboutUs = () => {
    return (
        <Layout>
            {/* Start Slider Area  */}
            <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Acerca de nosotros</span></h3>
                                    </div>
                                    <h1 className="title display-one">Somos más que una empresa <br />de Soluciones Tecnológicas</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Ofrecemos servicios en tecnología digital que se adapta a las necesidades de nuestros clientes.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Nuestras mejores soluciones en su clase, son diseñadas y desarrolladas para satisfacer las necesidades de una amplia gama de clientes. Soluciones totalmente a la medida, que brindan a nuestros clientes la facilidad de usar la tecnología como herramienta de facil uso y los ayuden crecer, como a su vez ahorrar tiempo y energía normalmente asociados con el mantenimiento o puesta en ejecución. Ya sea que tengan una estructura tradicional, una simple idea o servicios basados en la nube, incorporamos tecnicas y metodologias en nuestras soluciones que permiten escalar fácilmente a medida que crecen las necesidades.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}



                {/* Start Elements Area  */}
                <div className="rwt-tab-area rn-section-gap">
                        <div className="container">
                            {/* <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = ""
                                        description = ""
                                    />
                                </div>
                            </div> */}
                            <TabTwo />
                        </div>
                    </div>
                    {/* End Elements Area  */}               

                {/* <Separator /> */}

                {/* <AboutOne image="./images/about/contact-image.jpg" />   */}
            
                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Nuestro grandioso equipo."
                                    title = "Miembros del equipo corporativo."
                                    description = ""
                                />
                            </div>
                        </div>
                        <TeamFour column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" teamStyle="team-style-three" />
                    </div>
                </div>
                {/* End Elements Area  */}
        </Layout>
    )
}

export default AboutUs;
