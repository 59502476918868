import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 18.5082124, 
      lng: -69.9812166
    },
    zoom: 0.2
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAw-yRmh8ss6WgmsYLrG5cU0PSquwrkoL4" }}
          defaultCenter={{lat: 18.5082124, lng: -69.9812166}}
          defaultZoom={15}          
        >
          <AnyReactComponent
            lat={18.5082124}
            lng={-69.9812166}
            text="Xcales Solutions"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapStyle;